body {
  -webkit-text-size-adjust: none !important;
}
.app-float-btn {
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: var(--app-float-btn-bg);
  --background: var(--app-float-btn-bg);
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
  pointer-events: auto;
}
.app-back-color {
  background: #f8f8f7;
}

.video-box{
  height: 100%;
  width: 100%;
  position: relative;


}
.video-icon{
  font-size: 60px;
  position: absolute;
  width: 100%;
  height: 100%;

  color: #aeaeae;
  // left: 5px;
  // top: 5px;
  // background: #ffffff;
}
.photo{
  background: none !important;
}

textarea::placeholder {
  font-size: 14px ;
  color: #CCCCCC;
}
input::placeholder {
  font-size: 14px ;
  color: #CCCCCC;
}

.app-icon-btn {
  width: 32px;
  height: 32px;
  --background: var(--app-float-btn-bg);
  --padding-start: 0;
  --padding-end: 0;
  margin: 0;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
}

.app-float-shadow {
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.20);
}

ion-button {
  --color: var(--app-color);
  --padding-start: 0;
  --padding-end: 0;
  // --background: transparent;
}

// ion-header {
//   background: var(--app-nav-background);
//   // border-bottom: 0.55px solid var(--app-nav-bottom);
// }

// main {
//   background:red;
// }

// .overscroll{
//   // background:red !important;
// }
ion-header[translucent] {
  background: transparent;
  ion-toolbar {
    --background:  transparent;
    --ion-color-base: transparent !important;
  }
  &.header-ios ion-toolbar:last-child {
    --border-width: 0;
  }
  &.header-md::after {
    display: none;
  }
  .header-background {
    display: none;
  }
}

ion-title {
  --color: var(--app-color);
}
ion-toolbar {
  --ion-toolbar-background: var(--app-nav-background);
  ion-button {
    --ion-toolbar-color: var(--app-nav-gray);
  }
}

ion-content {
  padding: 16px;
  --color: var(--app-color);
}

ion-list {
  padding: 16px;
  --ion-item-background: var(--app-main-color);
}

.ion-color.sc-ion-searchbar-ios-h .searchbar-cancel-button.sc-ion-searchbar-ios {
  color: var(--clear-button-color);
}

.list-md {
  padding: 16px;
  --ion-item-background: var(--app-main-color);
}

.item .sc-ion-label-ios-h {
  --color: var(--app-color);
}

// 修复弹出框时左边、上边有莫名白边的bug
.sc-ion-modal-md-h {
  left: -1px;
  top: -1px;
}

.transparent-modal {
  --background: transparent;
}

.transparent-car-modal {
  --background: #fff;
}


input[type=text],
input[type=password],
input[type=number],
input[type=tel],
input[type=search] {
  border: none;
  outline: none;
  background: transparent;
}

textarea {
  resize: none;
  border: none;
}

.app-toast {
  --width: fit-content;
  --ion-color-step-800: #e0e0e0;
  --background: #e0e0e0;
  --box-shadow: none;
  --color: rgba(0, 0, 0, .7);
  --ion-font-family: PingFangSC-Medium;
  --border-radius: 4px;
}


ion-button.md, ion-button.md.ion-activated {
  --box-shadow: none;
}

ion-button.app-back-btn {
  width: 44px;
  height: 44px;
  --background: transparent;
  ion-icon {
    color: #aeaeae;
    width: 16px;
    height: 16px;
  }
}


.bottom-line {
  position: relative;
  // height: 1px;
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    border-bottom: 1px solid rgba(200,200,200,0.94);

    @media (resolution: 3dppx) {
      transform: scaleY(0.3);
    }
    @media (resolution: 2dppx) {
      transform: scaleY(0.5);
    }
  }
}

.full-container {
  position: relative;
}

.full-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

ion-button {
  margin: 0;
}


// ant mobile
// modal content
.am-modal-content {
  background-color: var(--am-modal-content-bg, #fff);
}

// use for fix ios tabbar cover modal error
.am-modal-popup .am-modal-content {
  padding-bottom: var(--am-modal-padding-bottom, env(safe_area-inset-bottom));
}

// map element styles

// .ev-task-position {
//   width: 15px;
//   height: 19px;
//   background: url('/assets/events/location@2x.png') center / 15px 19px;
//   .number {
//     line-height: 14px;
//     font-family: PingFangSC-Medium;
//     font-size: 10px;
//     color: #0a84ff;
//     text-align: center;
//   }
// }

  .complate-icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: rgba(10,132,255,0.80);
    border: 1px solid #0A84FF;
    &.gray {
      background: #808080;
    }
  }

  .uncomplate-icon {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: rgba(255,153,51,0.80);
    border: 1px solid #FF9933;
    &.gray {
      background: #808080;
    }
  }

  .uncomplate-icon-xc {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #e78bb5;
    border: 1px solid #e78bb5;
    &.gray {
      background: #808080;
    }
  }



  .point-bg-icon {
    width: 72px;
    height: 40px;
    background: url('/assets/common/dianwei.svg') center / 72px 40px no-repeat;
  }

  .point-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .searchbar-search-icon {

    right: -10px !important;
    color: #0A84FF !important;
  }
  .searchbar-input-container{
    position: relative !important;
  }






.ev-task-position {
  min-width: 37px;
	min-height: 22px;
  padding: 2px;
  background-color: #0a84ff;
  border-radius: 4px;
  position: relative;
  z-index: 999;
  & ::before{
    content: '';
    display: inline-block;
    // background-color: #0a84ff;
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid ;
    border-color: #0a84ff transparent transparent transparent ;

  }
  & ::after{
    content: '';
    display: inline-block;
    background-color: #0a84ff;
    width: 3px;
    height: 14px;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
  .number {
    padding: 2px;
    line-height: 14px;
    font-family: PingFangSC-Medium;
    font-size: 10px;
    color: #fff;
    white-space: nowrap;

    text-align: center;
  }
  &.warn{
    background-color: #ff9933;

    & ::after{
    background-color: #ff9933;

    }
    & ::before{
    border-color: #ff9933 transparent transparent transparent ;

    }
    // background: url('/assets/events/location@2x.png') center / 15px 19px;

  }
  &.gray {
    background-color: #808080;

    & ::after{
    background-color: #808080;

    }
    & ::before{
    border-color: #808080 transparent transparent transparent ;

    }
  }
}


.ev-task-source {
  white-space: normal;
  .buble {
    background: #fff;
    border-radius: 4px;
    white-space: nowrap;
    position: relative;
    box-shadow: 0px 2px 4px 0px
		rgba(0, 0, 0, 0.1);
    padding: 5px;
    font-family: PingFangSC-Medium;
    font-size: 12px;
    .name {
      color: #2f3034;
    }
    &::after {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #fff;
      position: absolute;
      /* bottom: -10px; */
      top: 100%;
      left: calc(50% - 5px);
    }
  }
  .marker {
    width: 22px;
    height: 37px;
    background: url('/assets/common/map-task-source@2x.png') center / 22px 37px no-repeat;
    margin: 8px auto 0;
  }
}


.line-markers {
  width: 40px;
  height: 40px;
  background: url('/assets/common/tuijian.png') center / 40px 40px no-repeat;
  // margin: 8px auto 0;
  .num {
    .num-box {
      height: 40px;
      width: 40px;
      padding-top: 7px;
      .img-noGreen {
        background-color: #0A84FF;
      }
      .img-green {
        background-color: rgba(13,189,104,0.80);
      }
    }
  }
}

.slideV-enter-active {
  margin-top: 50px;
}

.confirm-panel {
  margin-bottom: 50px;
}

.line-markers-comp {
  width: 40px;
  height: 40px;
  background: url('/assets/common/wancheng.png') center / 40px 40px no-repeat;
  // margin: 8px auto 0;
  .num {
    .num-box {
      height: 40px;
      width: 40px;
      padding-top: 7px;
    }
  }
}







// 上报
.report-bot{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  // background-color: rgb(204, 10, 10);
  background:url('/assets/pollute/add.svg') center  no-repeat;
  background-size:  contain;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 99;

}

.report-bot-int{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;

  // background-color: rgb(204, 10, 10);
  // background:url('/assets/events/report.svg') center  no-repeat;
  // background-size:  contain;
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 99;
  background: #FFFFFF;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.14);
  border-radius: 50%;
  span {
    font-size: 12px;
    color: #333333;
  }

}

.map-bot{
  width: 60px;
  height: 60px;
  border-radius: 30px;
  // background-color: rgb(204, 10, 10);
  background:url('/assets/pollute/map.png') center  no-repeat;
  background-size:  contain;
  position: absolute;
  right: 20px;
  bottom: 80px;
  z-index: 99;

}

.map-bot-int{
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  position: absolute;
  right: 20px;
  bottom: 80px;
  z-index: 99;
  background: #FFFFFF;
  box-shadow: 0 2px 7px 1px rgba(0,0,0,0.14);
  border-radius: 50%;
  span {
    font-size: 12px;
    color: #333333;
  }
}

// 顶部title
.titel-color{
  color:#666 ;
}

.titel-end {
  margin-right: 10px;
  color: #0a84ff;
}

.week-panel {
  margin-top: 10px;
}
