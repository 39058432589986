/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.flex {
  display: flex;
}

.flx-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flx-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flx-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flx-space {
  justify-content: space-between;
}

.flx1 {
  flex: 1;
}

.m-l5 {
  margin-left: 5px;
}

.m-l10 {
  margin-left: 10px;
}

.m-l15 {
  margin-left: 15px;
}

.m-l20 {
  margin-left: 20px;
}

.m-r10 {
  margin-right: 10px;
}

.m-r15 {
  margin-right: 15px;
}

.m-r20 {
  margin-right: 20px;
}

.m-t5 {
  margin-top: 5px;
}

.m-t10 {
  margin-top: 10px;
}

.m-t15 {
  margin-top: 15px;
}

.m-t20 {
  margin-top: 20px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-b20 {
  margin-bottom: 20px;
}

.no-wrap-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

ion-icon {
  --padding-start: 0;
  --padding-end: 0;
}

.amap-logo {
  img {
    display: none;
  }
}

ion-segment {
  overflow-x: unset;
}

.item-native {
  .item-inner {
    .input-wrapper {
      overflow: var(--app-item-overflow);
    }
  }
}

ion-segment {
  --border-width: 0;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  // width: 100%;
  display: inline-block;
  white-space: nowrap;
  // background-color: rgb(151, 45, 45);
}
// 地图
.amap-geolocation-con {
  .amap-geo {
    background: url(/assets/events/loction-btn@3x.png) no-repeat;
    background-size: cover;
    width: 35px;
    height: 35px;
    border: 1px solid rgba(204, 204, 204, 0);
    // border-radius: 3px;
    right: 4px;
    // z-index: -1;
  }
}
.amap-locate-loading {
  background-color: rgba(255, 255, 255, 0.253);
  box-shadow: 0 0 1px #ccc;
  border-radius: 10px;
  .amap-geo {
    width: 35px;
    height: 35px;
    transform: scale(0.4);
    background-image: url(https://webapi.amap.com/theme/v1.3/loading.gif);
    background-size: cover;
  }
}
.amap-copyright {
  color: #0000 !important;
}

.dv-echar-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 6px #ccc;
  display: inline-block;
  padding: 6px;
  border-radius: 4px;
  text-align: center;



  .title {
    color: #999;
  }
  .item {
    transform-origin: center;
    color: #555;
    transform: scale(0.7);
  }
}




